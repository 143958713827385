import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { browserHistory } from 'utils/history';
import { authRoute, privateRoute } from 'routes';

const AuthLayout = () => {
  const { isLoggedIn } = useSelector(({ profile }) => profile);

  React.useEffect(() => {
    if (isLoggedIn) {
      browserHistory.replace(privateRoute.home.path);
    }
  }, [isLoggedIn]);

  return (
    <div className='App Auth-Layout'>
      <Switch>
        {Object.values(authRoute).map(({ path, component }) => (
          <Route key={path} path={path} component={component} />
        ))}
        <Redirect from='/' to={authRoute.login.path} />
      </Switch>
      <div className='mb-20' />
    </div>
  );
};

export default AuthLayout;
